import React from 'react';
import logo from './logo.svg';
import './App.css';
import undercon from './resources/image/underconstruction.gif'

function App() {
  return (
    <div className="App">
      <h1>FPV Stick Cam</h1>
      <p>Welcome to FPV Stick Cam. I, <a target={'_blank'} href='https://twitter.com/philsullivan'>@philsullivan</a> am (are?) building an web app.</p>
      <p>Have you ever noticed that everything in FPV is really, really complicated? I'm a nerd, so I kinda love it, but sometimes I just want things to <i>just work.</i> This website is me trying to solve the problem for you.</p>
      <p>The idea is this - drag your black box logs onto this page, select colours, resolution, frame rate, hit a button and pay a small free (cloud services aren't free unfortunately) and a few minutes later get a link to a high quality render of your stick movements in your email.</p>
      <p>It'll look something like, (or better than) this:</p>
      <div><iframe width="560" height="315" src="https://www.youtube.com/embed/DmNsK9MHJCo" title="YouTube video player" frameBorder={'0'} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
      <p>It's early days, so I'm keen to get my hands on your log files to test with. If you're willing to send me some to play with, I'll give you some renders at the very least. Hit me up on <a target={'_blank'} href='https://twitter.com/philsullivan'>twitter</a> if this sounds of interest! <br></br>JB has a guide on how you might be able to access them here:</p>
      <div><iframe width="560" height="315" src="https://www.youtube.com/embed/VEALlGlcPbw" title="YouTube video player" frameBorder={'0'} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>

      <h3>Ideas that I've got so far, if this is a thing people actually want:</h3>
      <ul style={{display:'table',margin:'0 auto'}}>
        <li>Select the radio that you're actually using (TBS Tango, Taranis, RadioMaster etc) </li>
        <li>Custom skins/colours on the radio</li>
        <li>Change the colours or effects of the stick trails</li>
        <li>Choose from different types of trails (or turn them off entirely)</li>
        <li>Visible arm markers so you can easily sync it up to your footage</li>
        <li>Non 16:9 resolutions so you cool TikTok kids can use it in your toks(?)</li>
        <li>...</li>
        <li>Have anything you'd like to see? Hit me up!</li>
      </ul>
      <p>Coming soon</p>
      <img src={undercon} />
    </div>
  );
}

export default App;
